<template>
  <div>
    <AppHeader />
    <TTView>
      <VRow>
        <VCol class="text-center">
          <h1 class="font-weight-medium">
            {{ $t('surveyIsFinished') }}
          </h1>
        </VCol>
      </VRow>

      <VRow justify="center">
        <VCol
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <VImg
            :src="require('@/assets/img/surv_fin.svg')"
            width="100%"
          />
        </VCol>
      </VRow>
    </TTView>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';
import { mapMutations } from 'vuex';
import { toggleLoading, setCompanyId } from '@/plugins/vuex/mutationTypes';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';

export default {
  name: 'SurveyFinished',
  components: { AppHeader, TTView },
  computed: {
    userSurveySlug() {
      return this.$route.params.slug;
    },
  },
  async created() {
    try {
      this[toggleLoading](true);
      const welcomeResponse = await apiService.surveySessionWelcomeGet(this.userSurveySlug);
      const {
        companyId,
      } = welcomeResponse.data;
      this[setCompanyId](companyId);
    } catch (err) {
      errorHandler(err);
    } finally {
      this[toggleLoading](false);
    }
  },
  methods: {
    ...mapMutations([toggleLoading, setCompanyId]),
  },
};
</script>
